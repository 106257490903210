import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { isEmpty, isNumber, map } from 'lodash';
import { centsToDecimal, constants, DateService, useApplicationState } from '@apollo/core';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import { sportService } from '../../../core/constants';
import Label from '../../../shared/components/I18n/Label';
import Cashout from '../../../shared/components/Cashout/Cashout';
import Odds from '../../../shared/components/Odds';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import BetItemMultiHeader from './BetItemMultiHeader';
import BetSubItemViewSrm from '../BetList/BetSubItemViewSrm';
import BetSubItemViewSgm from '../BetList/BetSubItemViewSgm';
import {
  checkForExotic,
  checkForMultilegs,
  getCurrencySymbol,
  getLegsCountFromSelection,
  getLegsFromSgmOutcome,
  isTBD,
} from '../../../core/utils';
import BetExoticOrMultilegsSelections from './BetExoticOrMultilegsSelections';
import BetCouponDetails from './BetCouponDetails';
import Accordion from '../../../shared/components/Accordion/Accordion';
import BetSourceTooltip from '../../ClientProfile/HistoryTab/BetHistory/TableList/BetSourceTooltip';
import BetItemEventLink from '../../../shared/components/BetItemEventLink/BetItemEventLink';
import BetHistoryFreeBetBonus from '../../ClientProfile/HistoryTab/BetHistory/BetHistoryFreeBetBonus';
import BetCouponOdds from './BetCouponOdds';

const { BET_TYPE, BET_STATUSES, REJECTION_REASON, BONUS_TYPE, RaceLogicMarkets } = constants;

const { LIVE, RACING } = sportService;

const MyBetsItemOutcomeView = ({
  outcome,
  betType,
  updatedOdds,
  isExoticOrMultilegs,
  bonusInfoList,
}) => {
  const {
    outcomeId,
    outcomeInfo: {
      outcomeName,
      localizedOutcomeName,
      marketName,
      sportId,
      sportName,
      sportService,
      outcomeStatus,
      sameGameMultiLegs,
    },
  } = outcome;
  const t = useTranslate();

  const className = cx('coupon', {
    win: betType !== BET_TYPE.SINGLE && outcomeStatus === 'WIN',
    lose: betType !== BET_TYPE.SINGLE && outcomeStatus === 'LOSE',
  });

  return (
    <div key={outcomeId} className={className}>
      <div className='coupon__block'>
        {isExoticOrMultilegs ? (
          <BetExoticOrMultilegsSelections outcome={outcome} />
        ) : (
          <div className='coupon__outcomeName'>
            <BetItemEventLink outcomeInfo={outcome?.outcomeInfo}>
              {sportService === RACING ? (
                <RacingIcon id={sportId} name={sportName} />
              ) : (
                <span className={`icon sportIcon-${sportId}`} title={sportName} />
              )}
              <span className='name'>
                {sameGameMultiLegs?.length ? (
                  <>
                    {sameGameMultiLegs?.length}
                    {' '}
                    {t('legs')}
                    {' '}
                    {marketName}
                  </>
                ) : (
                  localizedOutcomeName || outcomeName
                )}
              </span>
            </BetItemEventLink>
          </div>
        )}

        <div className='coupon__odd'>
          <BetCouponOdds
            updatedOdds={updatedOdds}
            bonusInfoList={bonusInfoList}
            betType={betType}
            outcome={outcome}
            oddsMinDecimal={2}
          />
        </div>
      </div>
      {!isExoticOrMultilegs && <BetCouponDetails outcome={outcome} betType={betType} />}
    </div>
  );
};

const MyBetsItemToReturn = (props) => {
  const { outcomes, amount, betOdds, payout, betStatus, currencySymbol, isFreeBet } = props;

  let toReturn = payout;

  if (
    [BET_STATUSES.UNSETTLED, BET_STATUSES.PENDING_APPROVAL, BET_STATUSES.CANCELLED].includes(
      betStatus,
    )
  ) {
    toReturn = amount * betOdds;

    if (toReturn && isFreeBet) {
      toReturn -= amount;
    }
  }

  return isTBD({ outcomes }) ? (
    <Label message='tbd' />
  ) : (
    <>
      {/* {originalAmountPossibleWin && (
          <div className='cashout'>
            <span>
              {currencySymbol}
              {originalAmountPossibleWin}
            </span>
          </div>
        )} */}
      <span>
        {currencySymbol}
        {centsToDecimal(toReturn)}
      </span>
    </>
  );
};

const MyBetsItemOdds = ({
  outcomes,
  betOdds,
  originalBetOdds,
  rejectionReason,
  defaultOdds = null,
}) => {
  if (!betOdds) {
    return defaultOdds;
  }

  // eslint-disable-next-line no-nested-ternary
  return isTBD({ outcomes }) || betOdds <= 1 ? (
    <Label message='tbd' />
  ) : originalBetOdds && rejectionReason === REJECTION_REASON.ODDS_REDUCED ? (
    <>
      <div className='odd__default'>
        <span>
          <Odds value={originalBetOdds} decimal={3} minDecimal={2} />
        </span>
      </div>
      <div className='odd__changed'>
        <Odds value={betOdds} decimal={3} minDecimal={2} />
      </div>
    </>
  ) : (
    <Odds value={betOdds} decimal={3} minDecimal={2} />
  );
};

const MyBetsItemDefaultView = (props) => {
  const { bet, replaceBet, editBetAllowed, toggleEditMode, originalBets, collapsedMulti } = props;

  /*   const eventStartTime = useMemo(() => bet.outcomes?.[0].outcomeInfo.eventStartTime, [bet.outcomes]);

  const time = useMemo(() => {
    if (!eventStartTime) {
      return '';
    }

    const formatterStartDate = DateService.getFormattedDate(eventStartTime);
    const formatterStartTime = DateService.getFormattedTime(eventStartTime);

    return `${formatterStartDate} ${formatterStartTime}`;
  }, [eventStartTime]); */

  const {
    betId,
    betOdds,
    betStatus,
    amount,
    outcomes,
    estimatedCashOut,
    minPartialCashOut,
    estimatedPartialCashOut,
    cashOutCoefficient,
    betType,
    // todo: Order Bets do not have this
    betMoney = {},
    betSize,
    rejectionReason,
    updatedOdds,
    bonusInfoList,
  } = bet;

  const { payout = 0, currency = 'AUD' } = betMoney;

  const [racingState] = useRacingState();
  const { layout } = useApplicationState();

  const t = useTranslate();
  const currencySymbol = getCurrencySymbol(currency);
  const amountToDisplay = centsToDecimal(amount);

  const isExoticOrMultilegs = useMemo(() => checkForExotic(bet) || checkForMultilegs(bet), [bet]);

  const [originalAmountToDisplay, originalAmountPossibleWin, originalBetOdds] = useMemo(() => {
    if (isEmpty(originalBets)) return [];
    const { amount, betOdds } = originalBets[originalBets.length - 1] || {};
    const originalAmountToDisplay = amount ? centsToDecimal(amount) : null;
    let originalAmountPossibleWin = amount ? centsToDecimal(amount * betOdds) : null;

    if (bet?.bonusInfoList[0]?.bonusType === BONUS_TYPE.FREEBET && originalAmountPossibleWin) {
      originalAmountPossibleWin -= amount;
    }

    return [originalAmountToDisplay, originalAmountPossibleWin, betOdds];
  }, [originalBets, bet?.bonusInfoList]);

  const betStatusLabelClassName = cx('bets__status__label', {
    win: betStatus === BET_STATUSES.WIN || betStatus === BET_STATUSES.CASH_OUT,
    lose: betStatus === BET_STATUSES.LOSE,
    pending: betStatus === BET_STATUSES.VOID,
    process: betStatus === BET_STATUSES.PENDING_APPROVAL,
    void: [BET_STATUSES.UNSETTLED, BET_STATUSES.CANCELLED, BET_STATUSES.REJECTED].includes(
      betStatus,
    ),
    // void: betStatus === BET_STATUSES.UNSETTLED || betStatus === BET_STATUSES.CANCELLED || betStatus === BET_STATUSES.REJECTED,
  });

  const isMultiple = useMemo(() => bet.betType === BET_TYPE.MULTIPLE, [bet.betType]);

  const isSameRaceMultiple = useMemo(
    () => bet.outcomes.some(
      ({ outcomeInfo: _ }) => _.marketTypeId === RaceLogicMarkets.SAME_RACE_MULTI,
    ) && !isMultiple,
    [bet.outcomes, isMultiple],
  );

  const isSameGameMultiple = useMemo(
    () => bet.outcomes.some(({ outcomeInfo: _ }) => _.marketName === 'Same Game Multi') && !isMultiple,
    [bet.outcomes, isMultiple],
  );

  const betsClassName = useMemo(
    () => cx([
      'bets__block__content',
      isMultiple && 'bets__block__content-multiple',
      isSameRaceMultiple && 'bets__block__content-same-race-multiple',
      isSameGameMultiple && 'bets__block__content-same-game-multiple',
    ]),
    [bet.betType, isSameGameMultiple, isSameRaceMultiple],
  );

  const multiBetTitle = useMemo(() => {
    if (isMultiple) {
      return t('Leg Multi');
    }
    if (isSameRaceMultiple) {
      return t('Same Race Multi');
    }
    if (isSameGameMultiple) {
      return t('Same Game Multi');
    }
    return '';
  }, [t, isMultiple, isSameGameMultiple, isSameRaceMultiple]);

  const legsCount = useMemo(() => {
    if (isMultiple) {
      return outcomes.length;
    }
    if (isSameRaceMultiple) {
      return getLegsCountFromSelection(outcomes[0]?.outcomeInfo?.parameterValue);
    }
    if (isSameGameMultiple) {
      return outcomes[0]?.outcomeInfo?.sameGameMultiLegs?.length;
    }
  }, [outcomes, isSameRaceMultiple, isSameGameMultiple]);

  const getLegs = useCallback((outcome) => getLegsFromSgmOutcome(outcome), []);

  const getRunners = useCallback(
    (outcome) => {
      if (!racingState || !outcome || !outcome.outcomeInfo) {
        return [];
      }
      if (
        racingState.betslipRunnersByEventId
        && racingState.betslipRunnersByEventId[outcome.outcomeInfo.eventId]
        && racingState.betslipRunnersByEventId[outcome.outcomeInfo.eventId].length
      ) {
        return racingState.betslipRunnersByEventId[outcome.outcomeInfo.eventId];
      }
      return outcome.outcomeInfo.runners || [];
    },
    [racingState?.betslipRunnersByEventId],
  );

  const outcomeInfo = useMemo(
    () => map(outcomes, (outcome) => {
      if (isSameGameMultiple) {
        return (
          <div className='event--multi__wrapper'>
            {isMultiple ? (
              <BetItemEventLink outcomeInfo={outcome?.outcomeInfo}>
                <div className='event__event-name'>{outcome?.outcomeInfo.localizedEventName}</div>
                <div className='event-time event-time--bet'>
                  {DateService.getFormattedDateWithTime(outcome?.outcomeInfo.eventStartTime)}
                </div>
              </BetItemEventLink>
            ) : null}
            <BetSubItemViewSgm
              key={outcome.outcomeId}
              sgmLegsDataDefault={getLegs(outcome)}
              collapsedMulti={collapsedMulti}
            />
          </div>
        );
      }
      if (isSameRaceMultiple) {
        return (
          <div className='event--multi__wrapper'>
            {isMultiple ? (
              <BetItemEventLink outcomeInfo={outcome?.outcomeInfo}>
                <div className='event__event-name'>{outcome?.outcomeInfo.localizedEventName}</div>
                <div className='event-time event-time--bet'>
                  {DateService.getFormattedDateWithTime(outcome?.outcomeInfo.eventStartTime)}
                </div>
              </BetItemEventLink>
            ) : null}
            <BetSubItemViewSrm
              key={outcome.outcomeId}
              collapsedMulti={collapsedMulti}
              outcome={{
                ...outcome,
                selection: outcome.outcomeInfo.parameterValue,
              }}
              runners={getRunners(outcome)}
            />
          </div>
        );
      }
      return (
        <MyBetsItemOutcomeView
          isExoticOrMultilegs={isExoticOrMultilegs}
          key={outcome.outcomeId}
          outcome={outcome}
          runners={getRunners(outcome)}
          betType={betType}
          updatedOdds={betUpdatedOdds}
          bonusInfoList={bonusInfoList}
        />
      );
    }),
    [outcomes, getRunners, isExoticOrMultilegs, isSameRaceMultiple, isSameGameMultiple],
  );

  const hasOddsBoostBonus = betType === BET_TYPE.SINGLE
    && bonusInfoList?.some((bonus) => [BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS].includes(bonus.bonusType));

  const betUpdatedOdds = betType === BET_TYPE.SINGLE
    && bonusInfoList.some((bonus) => [BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS].includes(bonus.bonusType))
    ? { odds: bet.betOdds }
    : updatedOdds?.find((o) => `${o.outcomeId}` === outcomes[0].outcomeId);

  const percentage = hasOddsBoostBonus && betUpdatedOdds?.odds && outcomes[0]?.odds
    ? Math.round(((betUpdatedOdds.odds - outcomes[0].odds) / outcomes[0].odds) * 100)
    : 0;

  return (
    <div className={betsClassName}>
      {(isMultiple || isSameRaceMultiple || isSameGameMultiple) && (
        <BetItemMultiHeader
          multiBet={bet}
          title={multiBetTitle}
          total={bet.betOdds}
          betCount={legsCount}
          isSameMulti={!isMultiple && (isSameRaceMultiple || isSameGameMultiple)}
        />
      )}

      {layout.mobileDevice
      && collapsedMulti
      && outcomes.length > 1
      && !isSameGameMultiple
      && !isSameRaceMultiple ? (
        <Accordion collapsed titleOpen={t('hide_legs_more')}>
          {outcomeInfo}
        </Accordion>
        ) : (
          outcomeInfo
        )}
      {(isSameRaceMultiple || isSameGameMultiple)
        && isNumber(outcomes?.[0].homeScore)
        && isNumber(outcomes?.[0].awayScore) && (
          <div className='coupon__teams'>
            {outcomes?.[0].outcomeInfo.sportService === LIVE && <span className='isLive' />}

            {isNumber(outcomes?.[0].homeScore) && isNumber(outcomes?.[0].awayScore) && (
              <span className='score'>
                {' '}
                {outcomes?.[0].homeScore}
                :
                {outcomes?.[0].awayScore}
                {' '}
              </span>
            )}
            {/* {
              (outcomes?.[0].outcomeInfo.sportService !== LIVE) && time && (
                <span className='time'>
                  {time}
                </span>
              )
            } */}

            {/* <span className='teams'>
            {outcomes?.[0].outcomeInfo.localizedEventName || outcomes?.[0].outcomeInfo.eventName}
          </span> */}
          </div>
      )}

      <div className='result'>
        <div className='result__block'>
          <Label message='bet' render={(t) => <div className='name'>{t}</div>} />
        </div>
        <div className='result__block'>
          <Label
            message={isExoticOrMultilegs ? 'exotic_flexi' : 'To return'}
            render={(t) => <div className='name'>{t}</div>}
          />
        </div>

        <div className='result__block'>
          {isExoticOrMultilegs ? (
            <Label message='combos' render={(t) => <div className='name'>{t}</div>} />
          ) : betType === BET_TYPE.SYSTEM ? (
            <Label message='bet_type_system' render={(t) => <div className='name'>{t}</div>} />
          ) : (
            <Label message='odds' render={(t) => <div className='name'>{t}</div>} />
          )}
        </div>
      </div>
      {originalAmountToDisplay
      && (!rejectionReason || rejectionReason !== REJECTION_REASON.ODDS_REDUCED) ? (
        <>
          <div className='result'>
            <div className='partial__title'>
              <Label message='not accepted' />
            </div>
          </div>
          <div className='result'>
            <div className='result__block'>
              <div className='order order--stake'>
                <div className='cashout'>
                  <span>
                    {currencySymbol}
                    {originalAmountToDisplay}
                  </span>
                </div>
              </div>
            </div>
            <div className='result__block'>
              {isExoticOrMultilegs ? (
                <div className='order'>
                  <div className='cashout'>
                    <span>
                      {Number.parseFloat(originalBets[0]?.betMoney.betUnitAmount).toFixed(3)}
                      %
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className='result__block' />
          </div>
          <div className='result'>
            <div className='partial__title text-success'>
              <Label message='accepted' />
            </div>
          </div>
        </>
        ) : null}
      <div className='result result--values'>
        <div className='result__block'>
          <div className='order order--stake'>
            <span>
              {currencySymbol}
              {amountToDisplay}
            </span>
          </div>
        </div>
        <div className='result__block'>
          <div className='order order--returns'>
            {isExoticOrMultilegs ? (
              <span>
                {Number.parseFloat(bet.betMoney.betUnitAmount).toFixed(3)}
                %
              </span>
            ) : (
              <MyBetsItemToReturn
                outcomes={outcomes}
                amount={amount}
                betOdds={betOdds}
                payout={payout}
                betStatus={betStatus}
                isFreeBet={bet?.bonusInfoList[0]?.bonusType === BONUS_TYPE.FREEBET}
                originalAmountPossibleWin={originalAmountPossibleWin}
                currencySymbol={currencySymbol}
              />
            )}
          </div>
        </div>
        <div className='result__block'>
          {betType === BET_TYPE.SYSTEM ? (
            <div className='order'>{betSize}</div>
          ) : (
            <div className='order'>
              {isExoticOrMultilegs ? (
                <span>{bet.outcomes[0].outcomeInfo.combos}</span>
              ) : (
                <MyBetsItemOdds
                  outcomes={outcomes}
                  betOdds={betOdds}
                  originalBetOdds={originalBetOdds}
                  rejectionReason={rejectionReason}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {bet.bonusInfoList?.length > 0 && (
        <div className='result__block'>
          <BetSourceTooltip bonusInfoList={bet.bonusInfoList} />
          {bet.bonusInfoList[0].bonusType === BONUS_TYPE.BET_RETURN ? (
            <div className='bonus__description'>{bet.bonusInfoList[0].props?.description}</div>
          ) : null}
          {!layout.desktopDevice && bet.bonusInfoList[0].bonusType === BONUS_TYPE.ODDS_BOOST ? (
            <div className='bonus__description'>
              +
              {percentage}
              %
            </div>
          ) : null}
        </div>
      )}

      <Cashout
        betId={betId}
        amount={amount}
        odds={betOdds}
        estimatedCashOut={estimatedCashOut}
        estimatedPartialCashOut={estimatedPartialCashOut}
        currencySymbol={currencySymbol}
        replaceBet={replaceBet}
        cashOutCoefficient={cashOutCoefficient}
        minPartialCashOut={minPartialCashOut}
      />
      {editBetAllowed && (
        <button type='button' className='buttonCancel' onClick={toggleEditMode}>
          <Label message='edit bet' />
        </button>
      )}
      <div className='bets__status'>
        <Label
          message={betStatus}
          render={(t) => <div className={betStatusLabelClassName}>{t}</div>}
        />
        <BetHistoryFreeBetBonus bet={bet} />
      </div>
    </div>
  );
};

export default MyBetsItemDefaultView;
