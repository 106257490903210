import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NotificationManager, prepareRequest } from '@apollo/core';
import Loader from '../../../../shared/components/Loader';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { addCssFileToHeadOnce, addJsFileToBodyOnce } from './htmlHelpers';

const isDef = (v) => v !== undefined && v !== null;

// eslint-disable-next-line max-len
// See https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/1495793713/Registering+and+Verifying+an+Individual+via+Document+Validation+and+Facial+Biometrics#Include-and-setup-javascript
//
// NODE_ENV is defined via webpack
// eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
const greenIdUiHost = NODE_ENV === 'production' ? 'simpleui-au.vixverify.com' : 'simpleui-test-au.vixverify.com';

// For standard greenID Web, use greenid.css.
// For greenID Web with document validation and facial biometrics, use greenid-mobile.css.
// See https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/8880252/CSS+Styling
const greenIdCssToLoad = [`https://${greenIdUiHost}/df/assets/stylesheets/greenid-mobile.css`];

const greenIdScriptsToLoad = [
  `https://${greenIdUiHost}/df/javascripts/greenidConfig.js`,
  `https://${greenIdUiHost}/df/javascripts/greenidui.min.js`,
];

const scrollToTop = () => {
  document.getElementsByClassName('main__page')[0].scroll({
    top: 0,
    behavior: 'smooth',
  });
};

const GreenIdVerification = () => {
  const [greenIdVerificationProps, setGreenIdVerificationProps] = useState();
  const [greenIdDataIsLoading, setGreenIdDataIsLoading] = useState(false);
  const [error, setError] = useState();
  const [needsVerification, setNeedsVerification] = useState(false);
  const [greenIdScriptsLoadedCounter, setGreenIdScriptsLoadedCounter] = useState(0);
  const [verifyButtonVisible, setVerifyButtonVisible] = useState(false);

  const t = useTranslate();

  const fetchGreenIdVerificationProps = useCallback(
    () => prepareRequest({
      url: 'client/greenid-verification-props',
      method: 'GET',
    })().then((data) => {
      setGreenIdVerificationProps(data);
      return data;
    }),
    [],
  );

  useEffect(() => {
    setGreenIdDataIsLoading(true);
    fetchGreenIdVerificationProps()
      .then(setGreenIdVerificationProps)
      .catch((err) => setError(err.message))
      .finally(() => {
        setGreenIdDataIsLoading(false);
      });
  }, [fetchGreenIdVerificationProps]);

  useEffect(() => {
    if (isDef(greenIdVerificationProps)) {
      setNeedsVerification(greenIdVerificationProps.currentVerificationState !== 'VERIFIED');
    }
  }, [greenIdVerificationProps]);

  const greenIdRemoteFilesAdded = useRef(false);

  useEffect(() => {
    if (needsVerification && !greenIdRemoteFilesAdded.current) {
      greenIdCssToLoad.forEach((url) => addCssFileToHeadOnce(url));
      greenIdScriptsToLoad.forEach((url) => addJsFileToBodyOnce({
        url,
        onLoad: () => setGreenIdScriptsLoadedCounter((prev) => prev + 1),
      }));
    }
  }, [needsVerification]);

  const onVerificationComplete = useCallback(() => {
    setVerifyButtonVisible(false);
    setGreenIdDataIsLoading(true);
    prepareRequest({
      url: 'client/greenid-verification:update',
      method: 'POST',
    })()
      .then(fetchGreenIdVerificationProps)
      .then((props) => {
        if (props.currentVerificationState === 'VERIFIED') {
          // display message
          NotificationManager.notify({
            type: 'notification',
            status: 'success',
            title: 'green_id_verified_title',
            message: 'green_id_verified_message',
          });
        } else {
          setVerifyButtonVisible(true);
        }
        return props;
      })
      .then(setGreenIdVerificationProps)
      .catch((err) => {
        setError(err.message);
        setVerifyButtonVisible(true);
      })
      .finally(() => {
        setGreenIdDataIsLoading(false);
        scrollToTop();
      });
  }, [fetchGreenIdVerificationProps]);

  const onSessionComplete = useCallback(
    (/* token, result */) => {
      onVerificationComplete();
    },
    [onVerificationComplete],
  );

  const onError = useCallback((token, error) => {
    console.log('onError', { token, error });
    // display error
    NotificationManager.notify({
      type: 'notification',
      status: 'error',
      title: 'green_id_verification_error',
      message: error,
    });

    // show button to open GreenID page again
    setVerifyButtonVisible(true);
  }, []);

  const onCancel = useCallback(
    (/* token, verificationState */) => {
      // display message that verification cancelled?
      NotificationManager.notify({
        type: 'notification',
        status: 'success',
        title: 'green_id_verification_cancelled_title',
        message: 'green_id_verification_cancelled_message',
      });

      // show button to open GreenID page again
      setVerifyButtonVisible(true);
    },
    [],
  );

  // const onSourceAttempt = useCallback((...args) => {
  //   console.log('onSourceAttempt', args);
  // }, []);

  const greenIdScriptsLoaded = useMemo(
    () => greenIdScriptsLoadedCounter >= greenIdScriptsToLoad.length,
    [greenIdScriptsLoadedCounter],
  );

  const showGreenIdPage = useCallback(() => {
    if (
      needsVerification
      && greenIdScriptsLoaded
      && isDef(greenIdVerificationProps)
      && greenIdVerificationProps.currentVerificationState !== 'VERIFIED'
    ) {
      // window.greenidUI exists when GreenID scripts are loaded
      // eslint-disable-next-line max-len
      // https://vixverify.atlassian.net/wiki/display/GREEN/Using+the+SimpleUI#UsingtheSimpleUI-JavascriptSetupforRegisteringaNewPerson
      // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
      greenidUI.setup({
        environment: 'test',
        frameId: 'greenid-verification-frame',
        // a function that, if supplied, will be invoked whenever an error is experienced
        errorCallback: onError,
        // a function that, if supplied, will be invoked whenever the verification session is
        // complete. The session may complete with a successful verification, an incomplete or
        // failed verification or the person may abandon the verification process
        sessionCompleteCallback: onSessionComplete,
        // a function that, if supplied, will be invoked whenever the person attempts a source
        // sourceAttemptCallback: onSourceAttempt,
        // a function that, if supplied, will be invoked whenever a person ends a session
        // by clicking on the “save and complete later” button.
        sessionCancelledCallback: onCancel,
        // a function that, if supplied, will be invoked before greenID starts processing.
        // If the function returns false, i.e. one or more fields on the registration form are
        // invalid, then greenID returns control to the customer page in order to remedy the
        // validation errors before proceeding.  This callback is used to prevent submitting
        // invalid data to greenID
        // preSubmitValidationCallback: console.log,
      });

      // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
      greenidUiBehaviours.initiateTooltips();

      // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
      greenidConfig.setOverrides();

      const { accountId, apiCode, token } = greenIdVerificationProps;
      // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
      greenidUI.show(atob(accountId), atob(apiCode), atob(token));

      setVerifyButtonVisible(false);
    }
  }, [
    greenIdScriptsLoaded,
    greenIdVerificationProps,
    needsVerification,
    onCancel,
    onError,
    onSessionComplete,
  ]);

  useEffect(() => {
    if (needsVerification && !verifyButtonVisible && greenIdScriptsLoaded) {
      showGreenIdPage();
    }
  }, [greenIdScriptsLoaded, needsVerification, showGreenIdPage, verifyButtonVisible]);

  const handleVerifyButtonClick = useCallback(() => {
    if (needsVerification && verifyButtonVisible && greenIdScriptsLoaded) {
      setVerifyButtonVisible(false);
      // showGreenIdPage();
    }
  }, [greenIdScriptsLoaded, needsVerification, showGreenIdPage, verifyButtonVisible]);

  useEffect(() => {
    if (verifyButtonVisible) {
      scrollToTop();
    }
  }, [verifyButtonVisible]);

  // eslint-disable-next-line no-nested-ternary
  return error ? (
    <div className='text-danger'>{error}</div>
  ) // eslint-disable-next-line no-nested-ternary
    : greenIdDataIsLoading ? (
      <Loader />
    ) : needsVerification ? (
      <>
        {verifyButtonVisible ? (
          <button type='button' className='btn btn-primary-outline' onClick={handleVerifyButtonClick}>
            {t('green_id_verify')}
          </button>
        ) : null}
        <div className='greenid-verification-frame__wrapper'>
          <div id='greenid-verification-frame' />
        </div>
      </>
    ) : (
      <div className='greenid-verification-status'>
        <span className='greenid-verification-status__verification-symbol verification-symbol verified' />
        <span className='greenid-verification-status__text'>{t('green_id_verified')}</span>
      </div>
    );
};

export default GreenIdVerification;
