import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { getAvailablePages, useApplicationState, SiteConfigManager } from '@apollo/core';
import { availableClientProfileRoutes, COMPONENT_TYPES } from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { isDateFuture } from './BonusesTable/bonusesUtils';
import { BonusSelector } from '../../../state/Bonus/Bonus';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

export const ALL_TAB_NAME = 'bet-bonus-all';

const BonusesNavigation = ({ bonusTabs, activeTab }) => {
  const t = useTranslate();
  const { authenticated } = useApplicationState();
  const bonuses = BonusSelector.selectAllBonuses();

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs],
  );

  const bonusLink = useMemo(() => tabs?.find((t) => t.type === 'bonuses')?.link || '', [tabs]);
  const path = `${clientProfilePath}/${bonusLink}/:bonusType?`;

  const hasBonuses = !!bonuses?.filter((b) => isDateFuture(b.props?.expiry)).length;

  const scrollRef = useRef(null); // Ref for the Scrollbars component
  const activeTabRef = useRef(null); // Ref for the active tab

  // Scroll to the active tab horizontally when the component mounts or updates
  useEffect(() => {
    if (scrollRef.current && activeTabRef.current) {
      const scrollbars = scrollRef.current;
      const activeTab = activeTabRef.current;

      // Get the position of the active tab
      const tabOffsetLeft = activeTab.offsetLeft;
      const tabWidth = activeTab.clientWidth;

      // Get the scrollbar's visible area
      const scrollLeft = scrollbars.getScrollLeft();
      const containerWidth = scrollbars.getClientWidth();

      // Check if the active tab is out of view horizontally and scroll it into view
      if (tabOffsetLeft < scrollLeft || tabOffsetLeft + tabWidth > scrollLeft + containerWidth) {
        scrollbars.scrollLeft(tabOffsetLeft);
      }
    }
  }, [activeTab, bonusTabs]); // Re-run this effect when the activeTab or tabs change

  if (!authenticated) return null;

  return hasBonuses ? (
    <div className='navigation navigation--secondary bonus-navigation'>
      <Scrollbars ref={scrollRef} className='scroll__container' autoHide>
        <div className='bonus-tabs-container' style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          {bonusTabs.map((tab) => (
            <NavLink
              key={tab}
              className={cx('bet-bonus--link', {
                active: activeTab === tab.toLowerCase(),
              })}
              to={generatePath(path, {
                bonusType: tab.toLowerCase(),
              })}
              ref={activeTab === tab.toLowerCase() ? activeTabRef : null} // Set ref on active tab
            >
              <div className='bet-bonus--icon'>
                <i className={`icons icon--${tab.toLowerCase()}`} title={t(tab)} />
              </div>
              <Label message={tab === ALL_TAB_NAME ? 'all_bonuses' : tab} />
              <span className='bet-bonus--counter'>
                {
                  bonuses.filter(
                    (b) => (tab === ALL_TAB_NAME || b.bonusType === tab) && isDateFuture(b.props?.expiry),
                  ).length
                }
              </span>
            </NavLink>
          ))}
        </div>
      </Scrollbars>
    </div>
  ) : null;
};

export default BonusesNavigation;
